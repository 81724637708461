<template>
  <InlineEditor
    class="RemarksCell gridMode"
    :allow-edit="allowEdit"
    :edit-mode-model="this"
    :on-save="onSave"
    :value="lineItem.lscoRemarks"
  />
</template>

<script>
import InlineEditor from '../InlineEditor.vue'
export default {
  components: {
    InlineEditor
  },
  inject: [ 'store' ],
  props: {
    allowEdit: {
      type: Boolean,
      default: false
    },
    lineItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editMode: false
    }
  },
  methods: {
    async onSave (newValue) {
      try {
        const lineItem = {
          lscoRemarks: newValue
        }
        const lineItemIds = [ this.lineItem.bomlinkId ]
        await this.store.bomEditorApi.updateLineItem({
          lineItemIds,
          lineItem
        })
        this.lineItem.lscoRemarks = newValue
      } catch (error) {
        this.store.popups.add({
          text: 'An error occurred trying to update remarks.',
          error: error.message
        })
      }
    }
  }
}
</script>
