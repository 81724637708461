<template>
  <div
    class="InlineEditor"
    :class="{ editing: editMode}"
    @click="onClick"
  >
    <div
      v-if="label && label.length"
      class="label"
    >
      {{ label }}
    </div>
    <div class="content">
      <div
        v-if="editMode"
        class="editMode"
      >
        <input
          ref="input"
          v-model="editValue"
          class="editValue"
          focus
          @blur="onBlur"
          @keydown.stop="onKeydown"
        >
      </div>
      <div
        v-else
        class="readMode"
      >
        <div
          class="readValue"
          :class="{ empty: isEmpty }"
          :title="currentValue"
        >
          {{ currentValue }}
        </div>
      </div>
    </div>
    <Spinner v-if="isUpdating" />
  </div>
</template>

<script>
import Spinner from './Spinner.vue'

export default {
  components: {
    Spinner
  },
  props: {
    allowEdit: {
      type: Boolean,
      default: false
    },
    emptyValue: {
      type: String,
      default: ''
    },
    editModeModel: {
      type: Object,
      required: true
    },
    editModeKey: {
      type: String,
      default: 'editMode'
    },
    label: {
      type: String,
      default: ''
    },
    onSave: {
      type: Function,
      required: true
    },
    value: {
      type: [ String, Number ],
      default: ''
    }
  },
  data () {
    return {
      editValue: '',
      isUpdating: false
    }
  },
  computed: {
    currentValue () {
      if (this.isUpdating) {
        return this.editValue
      }
      if (this.isEmpty) {
        return this.emptyValue
      }
      return this.value
    },
    editMode: {
      get () {
        return this.allowEdit && this.editModeModel[this.editModeKey]
      },
      set (value) {
        this.editModeModel[this.editModeKey] = value
      }
    },
    isEmpty () {
      if (typeof this.value === 'string') {
        return !(this.value && this.value.length)
      } else if (typeof this.value === 'number') {
        return false
      }
      return true
    }
  },
  watch: {
    editMode (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.setFocus()
      }
    }
  },
  methods: {
    cancel () {
      this.editMode = false
    },
    edit () {
      this.editValue = this.value
      this.editMode = true
    },
    onBlur () {
      if (this.editMode && this.editValue !== this.value) {
        this.save()
      } else {
        this.cancel()
      }
    },
    onKeydown (event) {
      if (event.key === 'Enter') {
        this.save()
      } else if (event.key === 'Escape') {
        this.cancel()
      }
    },
    onClick () {
      this.edit()
    },
    async save () {
      try {
        this.editMode = false
        this.isUpdating = true
        await this.onSave(this.editValue)
        this.isUpdating = false
      } catch (error) {
        this.isUpdating = false
      }
    },
    setFocus () {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  }
}
</script>

<style lang="scss">
.InlineEditor {
  $spinnerSize: 16px;
  $height: 32px;

  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  .label {
    margin-right: 0.5rem;
  }

  .content {
    display: flex;
    align-items: center;
    flex-grow: 2;
    flex-shrink: 1;
    padding: 0.75rem;
    border: 1px solid transparent;
    border-radius: 5px;
    overflow: hidden;
    height: $height;
  }

  &:hover {
    .content {
      border: 1px solid $gray-lines;
    }
  }

  &.editing {
    .content {
      border: 1px solid $blue;
    }
  }

  .editMode {
    width: 100%;

    .editValue {
      width: 100%;
      border: none;
      padding: 0;
    }
  }

  .readMode {
    overflow: hidden;

    .readValue {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .Spinner {
    position: absolute;
    right: 8px;
    background: transparent;
    top: calc((#{$height} - #{$spinnerSize}) / 2);

    svg {
      height: $spinnerSize;
      width: $spinnerSize;
    }
  }

  &.gridMode {
    .content {
      height: 100%;
      padding: 0;
      border: none;
      border-radius: 0;

      .readMode {
        padding: 4px;
      }

      .editMode {
        height: 100%;

        input {
          padding: 0 4px;
          height: 100%;
          width: 100%;
          background-color: white;
          border-radius: 0;
        }
      }
    }

    .Spinner {
      top: 4px;
    }
  }
}
</style>
