import localeCompare from './localeCompare.js'

// This function is intended for sorting arrays of LineItem records
export default (lhs, rhs) => {
  const materialDiff = localeCompare(lhs.materialDescription, rhs.materialDescription)
  const supplierDiff = localeCompare(lhs.supplierDescription, rhs.supplierDescription)
  const colorDiff = localeCompare(lhs.colorDescription, rhs.colorDescription)
  const quantityDiff = lhs.quantity - rhs.quantity
  const remarksDiff = localeCompare(lhs.lscoRemarks, rhs.lscoRemarks)
  return (
    materialDiff ||
    supplierDiff ||
    colorDiff ||
    quantityDiff ||
    remarksDiff
  )
}
