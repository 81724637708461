<template>
  <div
    class="BomResultsFilterList"
    @click.stop
  >
    <SelectAllLink
      :is-deselect-mode="model.allSelected"
      :on-select-all="model.selectAll"
      :on-deselect-all="model.deselectAll"
    />
    <ul>
      <li
        v-for="bom in lockedBoms"
        :key="bom.bompartId"
        @click.stop
      >
        <label :style="[bom.isLock && bom.powerCoreIndicator !== 'Y' ? { color: 'grey' } : bom.isLock && bom.powerCoreIndicator === 'Y' ? { color: '#c41230' } : bom.powerCoreIndicator === 'Y' ? { color: '#c41230' } : null]">
          <input
            v-model="value"
            type="checkbox"
            :value="bom.bompartId"
          >
          {{ bom.colorwayName }}
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import SelectAllLink from './SelectAllLink.vue'
export default {
  components: {
    SelectAllLink
  },
  inject: ['store'],
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    // for adding lock key to BOM, to grey out locked ones
    lockedBoms () {
      const { bomEditor, bomGrid } = this.store
      if (this.store.router.path === '/bom-grid' || this.store.router.path === '/edit-lock') {
        return this.model.bomsSorted.map((bom) => {
          bom['isLock'] = bomGrid.gridLockCheck(bom.bompartId)
          return bom
        })
      } else {
        return this.model.bomsSorted.map((bom) => {
          bom = { ...bom,
            isLock: bomEditor.checkMRPlock(bom.bompartId),
            powerCoreIndicator: bomEditor.checkPowerCore(bom.bompartId)
          }
          return bom
        })
      }
    },
    value: {
      get () {
        return this.model.filters
      },
      set (value) {
        this.model.filters = value
      }
    }
  },
  methods: {
    onDeselectAllClick () {
      this.model.deselectAll()
    },
    onSelectAllClick () {
      this.model.selectAll()
    }
  }
}
</script>

<style lang="scss">
.BomResultsFilterList {
  padding: 2rem 4rem;

  .SelectAllLink {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 2rem;
  }

  .locked {
    color: grey;
  }

  ul {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 25%;
    grid-template-rows: repeat(10, 1fr);
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 2rem;

    li {
      padding-right: 2rem;

      label {
        display: flex;
        align-items: center;
        color: $gray-text;

        input {
          margin-right: 1.5rem;
          flex-shrink: 0;
        }
      }
    }
  }
}
</style>
