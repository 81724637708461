<template>
  <AgGridVue
    v-model="lineItems"
    class="BomGrid ag-theme-balham"
    :default-col-def="{ filter: true }"
    :column-defs="columnDefs"
    :header-height="vars.headerHeight"
    :row-height="vars.rowHeight"
    :style="style"
    :suppress-cell-selection="true"
    loading-overlay-component-framework="GridOverlay"
    no-rows-overlay-component-framework="GridOverlay"
    @modelUpdated="onGridChanged"
    @gridColumnsChanged="onGridChanged"
    @virtualColumnsChanged="onGridChanged"
    @viewportChanged="onGridChanged"
    @rowDataChanged="onGridChanged"
    @componentStateChanged="onGridChanged"
  />
</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import ColorwayNameCell from './ColorwayNameCell.vue'
import ComponentCell from './ComponentCell.vue'
import debounce from 'debounce-promise'
import GridOverlay from './GridOverlay.vue'
import LineItemCellRenderer from './LineItemCellRenderer.vue'
import SectionCell from './SectionCell.vue'
import SectionFilter from './SectionFilter.vue'

export default {
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    ColorwayNameCell,
    GridOverlay,
    ComponentCell,
    LineItemCellRenderer,
    SectionCell,
    SectionFilter
    /* eslint-enable vue/no-unused-components */
  },
  inject: [ 'store' ],
  computed: {
    bomColumnDefs () {
      const { bomGrid } = this.store
      // to filter out unlocked BOM and add locked to locked ones.
      return bomGrid.selectedBoms.map((bom) => {
        const lockCheck = bomGrid.gridLockCheck(bom.bompartId)
        const powerCore = bomGrid.gridPowerCoreCheck(bom.bompartId)
        return {
          headerName: ((lockCheck && powerCore) || (!lockCheck && powerCore)) ? `${bom.colorwayName}` + '(Power Core)' : (lockCheck && !powerCore) ? `${bom.colorwayName}` + '(Locked)' : `${bom.colorwayName}`,
          field: bom.bompartId,
          headerComponentFramework: 'ColorwayNameCell',
          cellRendererFramework: 'LineItemCellRenderer',
          valueGetter: (params) => {
            // HACK: This forces the grid to render "empty" columns,
            // so we get our grid-lines.
            const { field } = params.column.colDef
            if (params.data[field]) {
              return params.data[field]
            }
            return [{}]
          }
        }
      })
    },
    columnDefs () {
      return [
        this.sectionColumnDef,
        this.componentColumnDef,
        ...this.bomColumnDefs
      ]
    },
    componentColumnDef () {
      return {
        headerName: 'Component - Component Location',
        field: 'component',
        pinned: 'left',
        lockPinned: true,
        rowDrag: false,
        suppressMovable: true,
        suppressMenu: true,
        headerClass: 'componentHeader',
        cellRendererFramework: 'ComponentCell'
      }
    },
    lineItems: {
      get () {
        return this.store.bomGrid.selectedLineItems
      },
      set (value) {
        // Not sure why we need both a getter and a setter for this,
        // but the grid definitely wants us to have both.
      }
    },
    sectionColumnDef () {
      return {
        headerName: 'Section',
        field: 'section',
        pinned: 'left',
        lockPinned: true,
        rowDrag: false,
        suppressMovable: true,
        headerClass: 'sectionHeader',
        cellClass: 'sectionCell',
        cellRendererFramework: 'SectionCell',
        filterFramework: 'SectionFilter',
        menuTabs: [ 'filterMenuTab' ],
        filterParams: {
          clearButton: true,
          applyButton: true,
          debounceMs: 200
        }
      }
    },
    style () {
      return {
        '--sub-cell-font-size': `${this.vars.subCellFontSize}px`,
        '--sub-cell-padding': `${this.vars.subCellPadding}px`,
        '--sub-cell-height': `${this.vars.subCellHeight}px`,
        '--resizer-height': `${this.vars.resizerHeight}px`,
        '--row-height': `${this.vars.rowHeight}px`
      }
    },
    vars () {
      const subCellFontSize = 14
      const subCellPadding = 4
      const subCellHeight = subCellFontSize + subCellPadding * 2
      const rowHeight = subCellHeight * 5 + 3
      const headerHeight = 60
      const resizerMarginTop = 8 // defined in the grid theme
      const resizerHeight = headerHeight - (resizerMarginTop * 2)

      return {
        subCellFontSize,
        subCellPadding,
        subCellHeight,
        headerHeight,
        resizerHeight,
        rowHeight
      }
    }
  },
  methods: {
    autoSize: debounce((params) => {
      const columnIds = params.columnApi.getAllColumns().map((column) => {
        return column.colId
      })
      params.columnApi.autoSizeColumns(columnIds)
    }, 250, { leading: true }),
    onGridChanged (params) {
      // $nextTick and the debounce give our custom cells time to
      // render before columns are autoSized.
      this.$nextTick(() => {
        this.autoSize(params)
      })
    }
  }
}
</script>

<style lang="scss">
.BomGrid {
  width: 100%;
  flex-grow: 1;

  .ag-root {
    .ag-pinned-left-header {
      border-right-width: 2px;
    }

    .sectionHeader,
    .componentHeader {
      width: 100%;
      height: 100%;

      .ag-header-cell-text {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 16px;
      }
    }

    .sectionCell {
      text-align: center;
      text-transform: uppercase;
    }

    .ag-row {
      border-bottom: 2px solid $grid-border-gray;

      &.ag-row-odd {
        background-color: $grid-row-odd-background;

        &.ag-row-hover {
          background-color: $grid-row-hover-background;
        }
      }
    }

    .ag-cell {
      padding-right: 0;
      padding-left: 0;
      border: none;
    }

    .ag-header-cell::after,
    .ag-header-group-cell::after {
      height: var(--resizer-height);
    }

    .ag-header-cell {
      h1 {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }

      h2 {
        font-size: 16px;
        font-weight: bold;
      }

      .ag-header-cell-menu-button {
        cursor: pointer;
      }
    }
  }
}
</style>
