<template>
  <div class="BomGridPage">
    <SearchSubHeader v-if="searchMode" />
    <PC9SubHeader v-if="bomMode" />
    <CarryOver />
  </div>
</template>

<script>
import CarryOver from '../components/CarryOver/CarryOver.vue'
import PC9SubHeader from '../components/BomGrid/PC9SubHeader.vue'
import SearchSubHeader from '../components/BomGrid/SearchSubHeader.vue'

export default {
  components: {
    CarryOver,
    PC9SubHeader,
    SearchSubHeader
  },
  inject: [ 'store' ],
  computed: {
    bomMode () {
      return this.store.router.query.mode === 'bom'
    },
    searchMode () {
      return this.store.router.query.mode === 'search'
    }
  }
}
</script>

<style lang="scss">
.BomGridPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
