<template>
  <div class="ComponentCell">
    <h2>{{ component }} - {{ componentLocation }}</h2>
    <LineItemLegend />
  </div>
</template>

<script>
import LineItemLegend from './LineItemLegend.vue'

export default {
  components: {
    LineItemLegend
  },
  inject: [ 'store' ],
  computed: {
    component () {
      return this.store.components.formatComponent(this.componentData.component)
    },
    componentData () {
      return this.params.data
    },
    componentLocation () {
      return this.store.components.formatComponentLocation(this.componentData.componentLocation)
    }
  }
}
</script>

<style lang="scss">
.ComponentCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--rowHeight);

  h2 {
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
  }
}
</style>
