<template>
  <div
    class="MSCEditor"
    @keydown.stop
  >
    <div class="editors">
      <div class="subsection material">
        <AutoComplete
          ref="materialCode"
          class="gridMode"
          type="material"
          value-key="lscoMaterialCode"
          labeler="materialName"
          :model="this"
          name="materialCode"
          :select-on-focus="true"
          @keydown.native.stop="onMaterialKeydown"
          @change="onMaterialChange"
        />
        <div
          class="description"
          :title="materialDescription"
        >
          {{ materialDescription }}
        </div>
      </div>

      <div class="subsection supplier">
        <AutoComplete
          ref="supplierCode"
          class="gridMode"
          :type="supplierLookupType"
          value-key="supplierCode"
          labeler="supplierName"
          :model="this"
          name="supplierCode"
          :select-on-focus="true"
          @keydown.native.stop="onSupplierKeydown"
          @change="onSupplierChange"
        />
        <div
          class="description"
          :title="supplierDescription"
        >
          {{ supplierDescription }}
        </div>
      </div>

      <div class="subsection color">
        <AutoComplete
          ref="colorId"
          class="gridMode"
          :type="colorLookupType"
          value-key="colorCode"
          labeler="colorName"
          :model="this"
          name="colorId"
          placeholder="None"
          :select-on-focus="true"
          @keydown.native.stop="onColorKeydown"
          @change="onColorChange"
        />
        <div
          class="description"
          :title="colorDescription"
        >
          {{ colorDescription }}
        </div>
      </div>
    </div>
    <div class="controls">
      <Spinner v-if="isSaving" />

      <button
        v-if="!isSaving"
        class="button cancel outline"
        @click="onCancelClick"
      >
        Cancel
      </button>
      <button
        v-if="!isSaving"
        ref="saveButton"
        class="button"
        @click="onSaveClick"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import AutoComplete from '../AutoComplete.vue'
import nullifyIfEmpty from '../../util/nullifyIfEmpty.js'
import Spinner from '../Spinner.vue'

export default {
  components: {
    AutoComplete,
    Spinner
  },
  inject: [ 'store' ],
  props: {
    lineItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isSaving: false,
      materialCode: '',
      materialDescription: '',
      supplierCode: '',
      supplierDescription: '',
      colorId: '',
      colorDescription: ''
    }
  },
  computed: {
    colorIdOrNull () {
      return nullifyIfEmpty(this.colorId)
    },
    colorLookupType () {
      return `materialSupplierColor/${this.materialCode}/${this.supplierCode}`
    },
    materialCodeOrNull () {
      return nullifyIfEmpty(this.materialCode)
    },
    supplierCodeOrNull () {
      return nullifyIfEmpty(this.supplierCode)
    },
    supplierLookupType () {
      return `materialSupplierColor/${this.materialCode}`
    }
  },
  watch: {
    materialCode (newValue, oldValue) {
      if (
        newValue !== oldValue &&
        newValue !== this.lineItem.materialCode
      ) {
        this.supplierCode = null
        this.supplierDescription = ''
        this.colorId = null
        this.colorDescription = ''
      }
    },
    supplierCode (newValue, oldValue) {
      if (
        newValue !== oldValue &&
        newValue !== this.lineItem.supplierCode
      ) {
        this.colorId = null
        this.colorDescription = ''
      }
    }
  },
  mounted () {
    this.materialCode = this.lineItem.materialCode
    this.materialDescription = this.lineItem.materialDescription
    this.supplierCode = this.lineItem.supplierCode
    this.supplierDescription = this.lineItem.supplierDescription
    this.colorId = this.lineItem.colorId
    this.colorDescription = this.lineItem.colorDescription
  },
  methods: {
    focusEditor (whichEditor) {
      this.$refs[whichEditor].setFocus()
    },
    onCancelClick () {
      this.$emit('close')
    },
    onColorKeydown (event) {
      if (event.key === 'Escape') {
        this.colorId = this.lineItem.colorId
        this.colorDescription = this.lineItem.colorDescription
      } else if (event.key === 'Enter') {
        this.$refs.colorId.blur()
        this.$refs.saveButton.focus()
      }
    },
    onColorChange ({ value, label }) {
      if (value === this.lineItem.colorId && label === '') {
        this.colorDescription = this.lineItem.colorDescription
      } else {
        this.colorDescription = label
      }
      this.$emit('resize')
    },
    onMaterialChange ({ value, label }) {
      if (value === this.lineItem.materialCode && label === '') {
        this.materialDescription = this.lineItem.materialDescription
      } else {
        this.materialDescription = label
      }
      this.$emit('resize')
    },
    onMaterialKeydown (event) {
      if (event.key === 'Escape') {
        this.materialCode = this.lineItem.materialCode
        this.materialDescription = this.lineItem.materialDescription
      } else if (event.key === 'Enter') {
        this.$refs.materialCode.blur()
        this.$refs.supplierCode.setFocus()
      }
    },
    onSaveClick () {
      this.save()
    },
    onSupplierChange ({ value, label }) {
      if (value === this.lineItem.supplierCode && label === '') {
        this.supplierDescription = this.lineItem.supplierDescription
      } else {
        this.supplierDescription = label
      }
      this.$emit('resize')
    },
    onSupplierKeydown (event) {
      if (event.key === 'Escape') {
        this.supplierCode = this.lineItem.supplierCode
        this.supplierDescription = this.lineItem.supplierDescription
      } else if (event.key === 'Enter') {
        this.$refs.supplierCode.blur()
        this.$refs.colorId.setFocus()
      }
    },
    async save () {
      if (this.materialCodeOrNull && this.supplierCodeOrNull) {
        try {
          this.isSaving = true
          const lineItem = {
            materialCode: this.materialCodeOrNull,
            supplierCode: this.supplierCodeOrNull,
            colorId: this.colorIdOrNull
          }
          const lineItemIds = [ this.lineItem.bomlinkId ]
          await this.store.bomEditorApi.updateLineItem({
            lineItemIds,
            lineItem
          })
          this.lineItem.materialCode = this.materialCode
          this.lineItem.materialDescription = this.materialDescription
          this.lineItem.supplierCode = this.supplierCode
          this.lineItem.supplierDescription = this.supplierDescription
          this.lineItem.colorId = this.colorId
          this.lineItem.colorDescription = this.colorDescription
          this.isSaving = false
          this.$emit('close')
        } catch (error) {
          this.isSaving = false
          this.store.popups.add({
            text: 'An error occurred trying to update material/supplier/color.',
            error: error.message
          })
        }
      } else {
        this.store.popups.add({
          text: 'Fill All Three Fields',
          error: 'Material,Supplier and Color fields are mandatory.'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.MSCEditor {
  background-color: $grid-row-hover-background;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  flex-shrink: 0;

  .subsection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: var(--sub-cell-height);
    border-bottom: 1px solid $grid-border-gray;
    border-radius: none;
    flex-shrink: 0;
    overflow-x: hidden;

    .AutoComplete {
      flex-shrink: 0;
      width: 128px;
    }

    .description {
      flex-shrink: 0;
      margin-left: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .editors {
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 4px;
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    height: calc(var(--sub-cell-height) * 2);

    .button {
      width: 80px;
      padding: 8px 16px;
      align-self: center;
      margin-right: 4px;
    }

    .Spinner {
      align-self: stretch;
      display: flex;
      align-items: center;
      margin-right: 8px;

      svg {
        height: var(--sub-cell-height);
        width: var(--sub-cell-height);
      }
    }
  }
}
</style>
