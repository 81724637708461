<template>
  <div class="LineItemCellRenderer">
    <GridLineItem
      v-for="(lineItem, index) in lineItems"
      :key="index"
      :line-item="lineItem"
      :subsection-value="subsectionValue"
      @resize="onCellResize"
    />
  </div>
</template>

<script>
import GridLineItem from './GridLineItem.vue'
import compareLineItems from '../../util/compareLineItems.js'

export default {
  components: {
    GridLineItem
  },
  computed: {
    lineItems () {
      return [ ...this.params.value ].sort(compareLineItems)
    },
    subsectionValue () {
      const susbsection = this.params.data.component
      return susbsection
    }
  },
  methods: {
    autoResizeColumn () {
      const { colId } = this.params.column
      this.params.columnApi.autoSizeColumns([ colId ])
    },
    onCellResize () {
      // Wait for the grid to re-render
      setTimeout(this.autoResizeColumn, 50)
    }
  }
}
</script>

<style lang="scss">
.LineItemCellRenderer {
  display: flex;
  line-height: normal;
  height: 100%;
  width: auto;
  overflow: hidden;
}
</style>
