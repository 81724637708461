<template>
  <AgGridVue
    class="CarryOver ag-theme-balham"
    :default-col-def="{ filter: true }"
    :column-defs="columnDefs"
    :row-data="rowData"
    :header-height="vars.headerHeight"
    :row-height="vars.rowHeight"
    :style="style"
    :grid-options="gridOptions"
    :suppress-cell-selection="true"
    :suppress-row-click-selection="true"
    loading-overlay-component-framework="GridOverlay"
    :row-selection="rowSelection"
    :locale-text="customLocaleText"
    @row-selected="onRowSelection($event)"
    @selection-changed="onSelectionChange($event)"
    @filterChanged="filterChanged"
  />
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import GridOverlay from '../BomGrid/GridOverlay.vue'
export default {
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    GridOverlay
  },
  inject: ['store'],
  data: function () {
    return {
      gridOptions: null,
      gridApi: null,
      rowSelection: null,
      carryover: new Set(),
      checkboxState: 'indeterminate',
      retriggerBoms: new Set(),
      // rowData: null,
      customLocaleText: {
        noRowsToShow: 'Only Carryover BOMs can be displayed here'
      }
    }
  },
  computed: {
    columnDefs () {
      return [
        {
          headerName: 'Season',
          field: 'season',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'PC9Cell',
          width: 300
        },
        {
          headerName: 'PC5 Name',
          field: 'productName',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCellLeft'
        },
        {
          headerName: 'PC9 Code',
          field: 'colorwayCode',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCell'
        },
        {
          headerName: 'PC9 Name',
          field: 'colorwayName',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCellLeft',
          width: 247
        },
        {
          headerName: 'Product Type',
          field: 'prodType',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCell'
        },
        {
          headerName: 'PDS Name',
          field: 'pdsFullName',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCell'
        },
        {
          headerName: 'New/Carryover',
          field: 'lscoNewCarryover',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCell',
          width: 150,
          filter: false
        },
        {
          headerName: 'Carryover',
          headerClass: 'sectionHeader',
          cellClass: 'sectionCell',
          lockPinned: true,
          rowDrag: false,
          sortable: true,
          suppressMovable: true,
          suppressMenu: true,
          headerCheckboxSelection: this.store.user.current.role === 'PD',
          checkboxSelection: params => {
            if (!this.store.user.current.isAdmin) {
              if (params.data.carryoverStatus === 'completed' || params.data.carryoverStatus === 'processing') {
                return false
              }
              return true
            } else {
              if (params.data.carryoverStatus === 'completed' || params.data.carryoverStatus === 'processing') {
                params.node.setSelected(params.data.carryoverStatus === 'completed')
                return true
              } else {
                return true
              }
            }
          },
          cellStyle: params => {
            return ((params.data.bompartId && this.store.user.role !== 'PD')) ? { 'pointer-events': 'none', opacity: '0.4' }
              : ''
          }
        },
        {
          headerName: 'Carryover Status',
          field: 'carryoverStatus',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCell'
        },
        {
          headerName: 'Carryover Status Comments',
          field: 'carryoverStatusComments',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCellLeft'
        },
        {
          headerName: 'PC9 Carried Over From',
          field: 'carriedFrom',
          width: 300,
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCell'
        },
        {
          headerName: 'Power Core',
          field: 'powerCoreIndicator',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCell',
          width: 150
        },
        {
          headerName: 'Carried Over On',
          field: 'carryoverStamp',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCell'
        },
        {
          headerName: 'Carried Over By',
          field: 'carryoverByFullName',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: false,
          resizable: true,
          cellClass: 'sectionCell'
        }
      ]
    },
    season () {
      // debugger
      const { mode } = this.store.router.query
      const season =
        mode === 'search'
          ? this.store.seasons.find(this.store.router.query.seasonId).seasonName
          : this.store.router.query.id
      return season
    },
    rowData () {
      this.carryover.clear()
      this.retriggerBoms.clear()
      const { bomGrid } = this.store
      let seasonName
      const data = bomGrid.selectedBoms.map((bom) => {
        bom.prodType = (bom.prodType === 'N') ? 'Product' : 'Blank'
        if (bom.carryoverSeasonId) {
          seasonName = this.store.seasons.find(bom.carryoverSeasonId)
        } else seasonName = ''
        return { ...bom, season: this.season, carriedFrom: seasonName ? seasonName.seasonName : '' }
      })
      const carryOverBoms = data.filter((row) => row.lscoNewCarryover === 'C')
      return carryOverBoms.length > 0 ? carryOverBoms : []
    },
    style () {
      return {
        '--sub-cell-font-size': `${this.vars.subCellFontSize}px`,
        '--sub-cell-padding': `${this.vars.subCellPadding}px`,
        '--sub-cell-height': `${this.vars.subCellHeight}px`,
        '--resizer-height': `${this.vars.resizerHeight}px`,
        '--row-height': `${this.vars.rowHeight}px`
      }
    },
    vars () {
      const subCellFontSize = 6
      const subCellPadding = 4
      const subCellHeight = subCellFontSize + subCellPadding * 2
      const rowHeight = subCellHeight * 5 + 3
      const headerHeight = 60
      const resizerMarginTop = 8 // defined in the grid theme
      const resizerHeight = headerHeight - resizerMarginTop * 2

      return {
        subCellFontSize,
        subCellPadding,
        subCellHeight,
        headerHeight,
        resizerHeight,
        rowHeight
      }
    }
  },
  beforeMount () {
    this.gridOptions = {
      columnDefs: [
        {
          checkboxSelection: true,
          cellStyle: params =>
            params.value === 'YOUR_VALUE'
              ? { 'pointer-events': 'none' }
              : { 'pointer-events': 'none' }
        }
      ]
    }
    this.rowSelection = 'multiple'
  },
  async mounted () {
    this.gridApi = this.gridOptions.api
  },
  methods: {
    async onSelectionChange (event) {
      this.getCheckboxState()
      const { bomGrid } = this.store
      if (this.carryover.size > 0 || this.retriggerBoms.size > 0) {
        try {
          await bomGrid.updateCarryover(this.carryover, this.retriggerBoms)
        } catch (err) {
          window.alert(err)
        }
      }
    },
    getCheckboxState () {
      const indeterminate = document.getElementsByClassName('ag-icon ag-icon-checkbox-indeterminate')[0].getAttribute('class')
      const checked = document.getElementsByClassName('ag-icon ag-icon-checkbox-checked')[0].getAttribute('class')
      // const unchecked = document.getElementsByClassName('ag-icon ag-icon-checkbox-unchecked')[0].getAttribute('class')
      if (!indeterminate.includes('ag-hidden')) {
        this.checkboxState = 'indeterminate'
      } else if (!checked.includes('ag-hidden')) {
        this.checkboxState = 'checked'
      } else {
        this.checkboxState = 'unchecked'
      }
    },
    onRowSelection (event) {
      const updatedLock = event.node.selected
      const currentLock = (event.data.carryoverStatus === 'completed' || event.data.carryoverStatus === 'processing')
      const { bompartId } = event.data
      if (updatedLock !== currentLock) {
        updatedLock ? this.carryover.add(bompartId) : this.retriggerBoms.add(bompartId)
      } else {
        this.carryover.has(bompartId) ? this.carryover.delete(bompartId) : this.retriggerBoms.delete(bompartId)
      }
    },
    filterChanged () {
      this.store.bomGrid.gridData = this.gridApi
    }
  }
}
</script>

<style lang="scss">
.CarryOver {
  width: 100%;
  flex: 1;

  .ag-root {
    .ag-pinned-left-header {
      border-right-width: 4px;
    }

    .sectionHeader {
      width: 100%;
      height: 100%;

      .ag-header-cell-text {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 16px;
      }
    }

    .PC9Cell {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 16px;
      font-family: "Overpass", sans-serif;
      font-weight: 500;
    }

    .sectionCell {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: "Overpass", sans-serif;
      font-weight: 500;
    }

    .sectionCellLeft {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 16px;
      font-family: "Overpass", sans-serif;
      font-weight: 500;
    }

    .ag-header-cell::after,
    .ag-header-group-cell::after {
      height: var(--resizer-height);
    }

    .ag-header-cell {
      h1 {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }

      h2 {
        font-size: 16px;
        font-weight: bold;
      }

      .ag-header-cell-menu-button {
        cursor: pointer;
      }
    }

    .ag-row-selected {
      background-color: unset !important;
    }
  }
}
</style>
