<template>
  <AgGridVue
    class="BomGrid ag-theme-balham"
    :default-col-def="{ filter: true }"
    :column-defs="columnDefs"
    :row-data="rowData"
    :header-height="vars.headerHeight"
    :row-height="vars.rowHeight"
    :style="style"
    :grid-options="gridOptions"
    :suppress-cell-selection="true"
    :suppress-row-click-selection="true"
    loading-overlay-component-framework="GridOverlay"
    no-rows-overlay-component-framework="GridOverlay"
    :row-selection="rowSelection"
    @row-selected="onRowSelection($event)"
    @selection-changed="onSelectionChange($event)"
  />
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import GridOverlay from '../BomGrid/GridOverlay.vue'
export default {
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    GridOverlay
  },
  inject: ['store'],
  data: function () {
    return {
      gridOptions: null,
      gridApi: null,
      rowSelection: null,
      lockedBom: new Set(),
      unlockedBom: new Set()
      // rowData: null
    }
  },
  computed: {
    columnDefs () {
      return [
        {
          headerName: 'Season',
          field: 'season',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: true,
          resizable: true,
          cellClass: 'PC9Cell',
          width: 300
        },
        {
          headerName: 'PC5 Name',
          field: 'productName',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: true,
          resizable: true,
          cellClass: 'sectionCell'
        },
        {
          headerName: 'PC9',
          field: 'colorwayCode',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: true,
          resizable: true,
          cellClass: 'sectionCell'
        },
        {
          headerName: 'PC9 Colorway name',
          field: 'colorwayName',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: true,
          resizable: true,
          cellClass: 'sectionCell',
          width: 247
        },
        {
          headerName: 'Product Type',
          field: 'prodType',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: true,
          resizable: true,
          cellClass: 'sectionCell'
        },
        {
          headerName: 'MRP Locked ?',
          headerClass: 'sectionHeader',
          cellClass: 'sectionCell',
          lockPinned: true,
          rowDrag: false,
          sortable: true,
          suppressMovable: true,
          headerCheckboxSelection: this.store.user.current.role === 'PD',
          suppressMenu: true,
          checkboxSelection: function (params) {
            if (params.data.lscoMrpLock === 'Y') {
              params.node.setSelected(true)
              return true
            } else {
              if (params.data.powerCoreIndicator === 'Y') {
                return false
              }
              return true
            }
          },
          cellStyle: params => {
            return (params.data.bompartId && this.store.user.role !== 'PD' && params.data.powerCoreIndicator === 'N') ? { 'pointer-events': 'none', opacity: '0.4' }
              : params.data.powerCoreIndicator === 'Y' ? { 'pointer-events': 'none', opacity: '0.4' } : ''
          }
        },
        {
          headerName: 'Power Core',
          field: 'powerCoreIndicator',
          headerClass: 'sectionHeader',
          lockPinned: true,
          rowDrag: false,
          suppressMovable: true,
          suppressMenu: true,
          resizable: true,
          cellClass: 'sectionCell'
        }
      ]
    },
    season () {
      // debugger
      const { mode } = this.store.router.query
      const season =
        mode === 'search'
          ? this.store.seasons.find(this.store.router.query.seasonId).seasonName
          : this.store.router.query.id
      return season
    },
    rowData () {
      this.lockedBom.clear()
      this.unlockedBom.clear()
      const { bomGrid } = this.store
      const data = bomGrid.selectedBoms.map((bom) => {
        bom.prodType = (bom.prodType === 'N') ? 'Product' : 'Blank'
        return { ...bom, season: this.season }
      })
      return data
    },
    style () {
      return {
        '--sub-cell-font-size': `${this.vars.subCellFontSize}px`,
        '--sub-cell-padding': `${this.vars.subCellPadding}px`,
        '--sub-cell-height': `${this.vars.subCellHeight}px`,
        '--resizer-height': `${this.vars.resizerHeight}px`,
        '--row-height': `${this.vars.rowHeight}px`
      }
    },
    vars () {
      const subCellFontSize = 6
      const subCellPadding = 4
      const subCellHeight = subCellFontSize + subCellPadding * 2
      const rowHeight = subCellHeight * 5 + 3
      const headerHeight = 60
      const resizerMarginTop = 8 // defined in the grid theme
      const resizerHeight = headerHeight - resizerMarginTop * 2

      return {
        subCellFontSize,
        subCellPadding,
        subCellHeight,
        headerHeight,
        resizerHeight,
        rowHeight
      }
    }
  },
  beforeMount () {
    this.gridOptions = {
      columnDefs: [
        {
          checkboxSelection: true,
          cellStyle: params =>
            params.value === 'YOUR_VALUE'
              ? { 'pointer-events': 'none' }
              : { 'pointer-events': 'none' }
        }
      ]
    }
    this.rowSelection = 'multiple'
  },
  mounted () {
    this.gridApi = this.gridOptions.api
  },
  methods: {
    async onSelectionChange (event) {
      const { bomGrid } = this.store
      if (this.lockedBom.size > 0 || this.unlockedBom.size > 0) {
        try {
          await bomGrid.editMrpLock(this.lockedBom, this.unlockedBom)
        } catch (err) {
          window.alert(err)
        }
      }
    },
    onRowSelection (event) {
      const updatedLock = event.node.selected
      const currentLock = !(event.data.lscoMrpLock !== 'Y') || event.data.powerCoreIndicator === 'Y'
      const { bompartId } = event.data
      if (updatedLock !== currentLock) {
        updatedLock
          ? this.lockedBom.add(bompartId)
          : this.unlockedBom.add(bompartId)
      } else {
        this.lockedBom.has(bompartId)
          ? this.lockedBom.delete(bompartId)
          : this.unlockedBom.delete(bompartId)
      }
    }
  }
}
</script>

<style lang="scss">
.BomGrid {
  width: 100%;
  flex: 1;

  .ag-root {
    .ag-pinned-left-header {
      border-right-width: 4px;
    }

    .sectionHeader {
      width: 100%;
      height: 100%;

      .ag-header-cell-text {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 16px;
      }
    }

    .PC9Cell {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 16px;
      font-family: "Overpass", sans-serif;
      font-weight: 500;
    }

    .sectionCell {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: "Overpass", sans-serif;
      font-weight: 500;
    }

    .ag-header-cell::after,
    .ag-header-group-cell::after {
      height: var(--resizer-height);
    }

    .ag-header-cell {
      h1 {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }

      h2 {
        font-size: 16px;
        font-weight: bold;
      }

      .ag-header-cell-menu-button {
        cursor: pointer;
      }
    }

    .ag-row-selected {
      background-color: unset !important;
    }
  }
}
</style>
