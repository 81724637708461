<template>
  <SubHeader class="SearchSubHeader">
    <template v-slot:leftSide>
      <h3>Search Results for {{ season }}</h3>

      <BomResultsMenu
        :model="store.bomGrid"
      />

      <router-link
        :to="searchLinkUrl"
        class="edit-search"
      >
        <EditIcon />
        Edit Search
      </router-link>
    </template>

    <template v-slot:rightSide>
      <button
        v-if="isCarryOverView"
        class="button"
        @click="clearFilters"
      >
        Clear Filters
      </button>
      <button
        v-if="isLockView"
        class="button"
        @click="updateBom"
      >
        Submit
      </button>
      <!-- <button
        class="button download"
        @click="generateReport"
      >
        Generate BOM Report
        <Spinner
          v-if="store.bomSearch.isLoading"
          color="white"
        />
      </button> -->
      <router-link
        class="button"
        :to="bomModeLink"
      >
        View in BOM Editor
      </router-link>
    </template>
  </SubHeader>
</template>

<script>
import BomResultsMenu from '../BomResultsMenu.vue'
import EditIcon from '../icons/EditIcon.vue'
import SubHeader from '../SubHeader.vue'
import AlertMessage from '../BomEditor/dialogs/AlertMessage'
export default {
  components: {
    BomResultsMenu,
    EditIcon,
    SubHeader
  },
  inject: [ 'store' ],
  computed: {
    isLockView () {
      return ((this.store.router.path === '/edit-lock' || this.store.router.path === '/carry-over') && this.store.user.role === 'PD')
    },
    isCarryOverView () {
      return this.store.router.path === '/carry-over'
    },
    bomModeLink () {
      const query = {
        ...this.store.router.query,
        mode: undefined
      }

      return {
        path: '/search-results/fabric',
        query,
        hash: this.store.router.hashString
      }
    },
    searchLinkUrl () {
      const { query } = this.store.router
      return {
        path: '/search',
        query
      }
    },
    season () {
      const { seasonId } = this.store.router.query
      const season = this.store.seasons.find(seasonId)
      return season.seasonName
    }
  },
  methods: {
    async updateBom () {
      let message = ''
      let title = ''
      let type = ''
      let subType = ''
      const { bomGrid, alertDialog } = this.store
      try {
        if (this.store.router.path === '/carry-over') {
          type = 'Carryover'
          subType = ' Carryover Request Submission'
          await bomGrid.carryoverBom()
          this.store.popups.messages.push({ 'text': 'Please refresh the page if status is still in Processing' })
        } else {
          type = 'MRP Lock'
          subType = 'MRP Lock Change Submit'
          await bomGrid.updateBom()
        }
        message = 'Success!'
        bomGrid.refresh()
        this.store.clickStream.obEvent(
          type,
          subType
        )
      } catch (err) {
        message = err
        title = 'Error'
      }
      if (type === 'MRP Lock') {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: message
          },
          title: title
        })
      }
    },
    generateReport () {
      this.store.bomSearch.generateGridReport()
    },
    clearFilters () {
      let grid = this.store.bomGrid.gridData
      grid.setFilterModel(null)
      grid.onFilterChanged()
    }
  }
}
</script>

<style lang="scss">
.SearchSubHeader {
  flex-grow: 0;

  .download.button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Spinner {
      width: 16px;
      height: 16px;
      margin-left: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
