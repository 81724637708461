<template>
  <div class="SelectAllLink">
    <div v-if="waiting">
      {{ waiting }}
    </div>
    <a
      v-else-if="isDeselectMode"
      @click.stop="deselectAll"
    >
      Deselect all
    </a>
    <a
      v-else
      @click.stop="selectAll"
    >
      Select all
    </a>
  </div>
</template>

<script>
export default {
  props: {
    onDeselectAll: {
      type: Function,
      required: true
    },
    isDeselectMode: {
      type: Boolean,
      default: false
    },
    onSelectAll: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      waiting: false
    }
  },
  methods: {
    deselectAll () {
      this.waiting = 'Deselecting…'
      setTimeout(() => {
        this.onDeselectAll()
        this.waiting = false
      }, 0)
    },
    selectAll () {
      this.waiting = 'Selecting…'
      setTimeout(() => {
        this.onSelectAll()
        this.waiting = false
      }, 0)
    }
  }
}
</script>
