<template>
  <ContextMenu
    class="BomResultsMenu"
    :x-offset="0"
    :y-offset="25"
    :force-below="true"
    frame-class-ex="bomResultsMenuFrame"
    menu-id="BomResultsMenu"
  >
    <template v-slot:button="slotProps">
      {{ countText }} BOMs
      <ChevronDownIcon />
      <div
        v-if="slotProps.isOpen"
        class="arrow"
        :class="{ showArrow: slotProps.isOpen }"
      />
    </template>

    <template v-slot:default>
      <BomResultsFilterList
        :model="model"
      />
    </template>
  </ContextMenu>
</template>

<script>
import BomResultsFilterList from './BomResultsFilterList.vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import ContextMenu from './ContextMenu.vue'

export default {
  components: {
    BomResultsFilterList,
    ChevronDownIcon,
    ContextMenu
  },
  inject: ['store'],
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    arrowShowing () {
      return this.store.contextMenu.currentId
    },
    countText () {
      return `${this.model.selectedCount} of ${this.model.count}`
    }
  }
}
</script>

<style lang="scss">
.BomResultsMenu {
  cursor: pointer;
  position: relative;

  .arrow {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 2px;
    transform: rotate(45deg);
    background: $white;
    position: absolute;
    right: -5px;
    bottom: -38px;
    opacity: 0;
    transition: 0.5s transform, 0.5s opacity;

    &.showArrow {
      opacity: 1;
    }
  }
}

.context-menu-portal-target {
  .menuFrame.bomResultsMenuFrame {
    width: 96vw;
    left: 2vw;
    border-radius: 0;
    border-top: none;

    &::after {
      display: none;
    }
  }
}
</style>
