<template>
  <div class="SectionFilter">
    <CheckOptions
      :options="sectionOptions"
      :model="this"
      name="selectedSections"
    />
  </div>
</template>

<script>
import CheckOptions from '../CheckOptions.vue'

export default {
  components: {
    CheckOptions
  },
  inject: [ 'store' ],
  data () {
    return {
      selectedSections: []
    }
  },
  computed: {
    sectionOptions () {
      return this.store.codes.sectionOptions
    }
  },
  watch: {
    selectedSections () {
      this.params.filterChangedCallback()
    }
  },
  methods: {
    doesFilterPass (params) {
      return this.selectedSections.includes(params.data.section)
    },
    isFilterActive () {
      return this.selectedSections.length
    }
  }
}
</script>

<style lang="scss">
.SectionFilter {
  padding: 8px;

  .CheckOptionItem:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
