<template>
  <div class="SectionCell">
    <h1>{{ section }}</h1>
  </div>
</template>

<script>
export default {
  inject: [ 'store' ],
  computed: {
    section () {
      return this.store.codes.sections[this.params.value]
    }
  }
}
</script>

<style lang="scss">
.SectionCell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 16px;
  }
}
</style>
