<template>
  <div class="ColorwayNameCell">
    <div class="colorwayName">
      <div class="colorwayCode">
        {{ colorwayCode }}
      </div>
      <div class="colorwayDescription">
        {{ colorwayDescription }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    colorwayCode () {
      return this.colorwaySplit[0]
    },
    colorwayDescription () {
      return this.colorwaySplit[1].toLowerCase()
    },
    colorwayName () {
      return this.params.displayName
    },
    colorwaySplit () {
      return this.colorwayName.split(/\s+(.+)/)
    }
  }
}
</script>

<style lang="scss">
.ColorwayNameCell {
  line-height: 1.4;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -1px;
  margin-right: -1px;
  font-size: 16px;

  .colorwayName {
    flex-grow: 1;
    text-align: center;
    padding: 4px;

    .colorwayDescription {
      display: inline-block;
      text-transform: capitalize;
      overflow-wrap: break-word;
      white-space: normal;
      width: 100%;
    }
  }
}
</style>
