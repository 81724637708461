<template>
  <SubHeader class="PC9SubHeader">
    <template v-slot:leftSide>
      <h3>{{ bompartName }}</h3>
    </template>

    <template v-slot:rightSide>
      <button
        v-if="isLockView"
        class="button"
        @click="updateBom"
      >
        Submit
      </button>
      <router-link
        class="button"
        :to="bomModeLink"
      >
        View in BOM Editor
      </router-link>
    </template>
  </SubHeader>
</template>

<script>
import SubHeader from '../SubHeader.vue'
import AlertMessage from '../BomEditor/dialogs/AlertMessage'
export default {
  components: {
    SubHeader
  },
  inject: [ 'store' ],
  computed: {
    isLockView () {
      return (this.store.router.path === '/edit-lock' && this.store.user.role === 'PD')
    },
    bomModeLink () {
      const query = {
        ...this.store.router.query,
        mode: undefined
      }

      return {
        path: '/bom/fabric',
        query
      }
    },
    bompartName () {
      const boms = this.store.bomGrid.rawBoms
      if (boms && boms.length) {
        return boms[0].bompartName
      }
      return ''
    }
  },
  methods: {
    async updateBom () {
      const { bomGrid, alertDialog } = this.store
      let message = ''
      let title = ''
      try {
        await bomGrid.updateBom()
        message = 'Success!'
        bomGrid.refresh()
      } catch (err) {
        message = err
        title = 'Error'
      }
      await alertDialog.show({
        component: AlertMessage,
        props: {
          message: message
        },
        title: title
      })
    }
  }
}
</script>
