<template>
  <div class="GridLineItem">
    <div
      v-if="isEmpty"
      class="noSubCells"
    />
    <MSCEditor
      v-else-if="mscEditMode"
      ref="mscEditor"
      :line-item="lineItem"
      @close="onMSCEditorClose"
      @resize="onMSCResize"
    />
    <div v-else>
      <div
        class="materialDescription subCell"
        @click="onMaterialClick"
      >
        {{ materialDescription }}
      </div>
      <div
        class="supplierDescription subCell"
        @click="onSupplierClick"
      >
        {{ supplierDescription }}
      </div>
      <div
        class="colorDescription subCell"
        @click="onColorClick"
      >
        {{ colorDescription }}
      </div>

      <QuantityCell
        class="quantity subCell"
        :allow-edit="allowEdit"
        :line-item="lineItem"
      />
      <RemarksCell
        class="remarks subCell"
        :allow-edit="allowEdit"
        :line-item="lineItem"
      />
    </div>
  </div>
</template>

<script>
import MSCEditor from './MSCEditor.vue'
import QuantityCell from './QuantityCell.vue'
import RemarksCell from './RemarksCell.vue'

export default {
  components: {
    MSCEditor,
    QuantityCell,
    RemarksCell
  },
  inject: [ 'store' ],
  props: {
    lineItem: {
      type: Object,
      required: true
    },
    subsectionValue: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      focusWhich: 'material',
      mscEditMode: false
    }
  },
  computed: {
    allowEdit () {
      // restrict edit functionality to unlocked BOM's only
      const bompartId = this.lineItem.bompartId
      const { bomGrid } = this.store
      const checkMRPlock = bomGrid.gridLockCheck(bompartId)
      const powerCoreBoms = bomGrid.gridPowerCoreCheck(bompartId)
      const hasPermission = this.store.rbac.hasPermission('edit', 'lineItem', {
        sectionName: this.lineItem.section
      })
      return (hasPermission && !(checkMRPlock) && !(this.seasonal) && !(powerCoreBoms))
    },
    colorDescription () {
      return this.lineItem.colorDescription
    },
    isEmpty () {
      return !this.lineItem.bomlinkId
    },
    materialDescription () {
      return this.lineItem.materialDescription
    },
    supplierDescription () {
      return this.lineItem.supplierDescription
    },
    seasonal () {
      const validLineItems = ['A', 'CrossSource', 'finishCrossSource', 'primaryFinish']
      if (validLineItems.includes(this.subsectionValue)) {
        return this.lineItem.seasonalLookId !== null
      } else {
        return false
      }
    }
  },
  methods: {
    onColorClick () {
      this.showEditor('colorId')
    },
    onMaterialClick () {
      this.showEditor('materialCode')
    },
    onSupplierClick () {
      this.showEditor('supplierCode')
    },
    onMSCEditorClose () {
      this.$emit('resize')
      this.mscEditMode = false
    },
    onMSCResize () {
      this.$emit('resize')
    },
    showEditor (focusWhich) {
      if (this.allowEdit) {
        this.$emit('resize')
        this.mscEditMode = true
        this.$nextTick(() => {
          this.$refs.mscEditor.focusEditor(focusWhich)
        })
      }
    }
  }
}
</script>

<style lang="scss">
.GridLineItem {
  height: 100%;
  flex-grow: 1;
  min-width: 120px;
  overflow: hidden;

  .MSCEditor {
    border-right: 1px solid $grid-border-gray;
  }

  .subCell {
    line-height: 1;
    font-size: var(--sub-cell-font-size);
    height: var(--sub-cell-height);
    padding: var(--sub-cell-padding);
    margin-right: 0;
    margin-left: 0;
    border-right: 1px solid $grid-border-gray;
    cursor: pointer;

    &:not(.remarks) {
      border-bottom: 1px solid $grid-border-gray;
    }

    &.InlineEditor.gridMode {
      padding: 0;
    }
  }

  .noSubCells {
    width: 100%;
    height: 100%;
    border-right: 1px solid $grid-border-gray;
  }
}
</style>
